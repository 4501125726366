function Home() {
  return (
    <div id="home" className="component">
      
    </div>
  )
}

export default Home;

/*
Figuring out what to add to this home page.
I can just add whatever is coming up. Doesn't have to be complex.
*/